export const seenthis = (adUnitPath: string, domId: string) => {
  window.highImpactJs = window.highImpactJs || {
    cmd: [],
  };
  window.highImpactJs.cmd.push(() => {
    window.highImpactJs.setConfig({
      topBarHeight: 116,
      plugins: ['gam'],
      zIndex: 99,
    });

    window.highImpactJs.setTemplateConfig('topscroll', {
      peekAmount: '70vh',
    });

    window.highImpactJs.defineSlot({
      slot: `${adUnitPath}`,
      template: 'topscroll',
      sizes: [[1, 2]],
      adUnitId: `${domId}`,
      waitForAdSignal: false,
    });
  });
};
