import { getCookie, setCookie } from '../storage/cookies';
import { md5 } from '../lib/md5';
import log from '../helpers/log';

const PPID_COOKIE = 'aapp';

/**
 * Retrieves The Publisher provided identifier (PPID)
 * from the cookie(The current source for PPID
 * is a random first-party cookie created called 'aapp',
 * previously tealium cookies were used) or
 * generates a new one if not present.
 * If the PPID is invalid, it returns undefined.
 *
 * @returns {string|undefined} - The PPID or undefined if invalid.
 */
const getPPID = (): string | undefined => {
  if (window.allerAds ) {
    const { googlePpId = undefined } = window.allerAds;
    if (googlePpId) return googlePpId;
  } else {
    const { googlePpId = undefined } = window;
    if (googlePpId) return googlePpId;  
  }

  try {
    const ppIdCookie = getCookie(PPID_COOKIE) || getNewPpid();

    const ppIdHashed = md5(`${ppIdCookie}`);

    log('ppIdCookie hash: ', ppIdCookie, ppIdHashed);

    if (window.allerAds) {
      window.allerAds.googlePpId = isValidPpid(ppIdHashed) ? ppIdHashed : undefined;
      return window.allerAds.googlePpId;

    } else {
      window.googlePpId = isValidPpid(ppIdHashed) ? ppIdHashed : undefined;
      return window.googlePpId;
    }
  } catch (e) {
    console.warn(`PPID: Failed to hash user identifier`);
  }
};

/**
 * Checks if a value is a valid hashed PPID.
 *
 * @param {string} val - The value to check.
 * @returns {boolean} - True if the value is a valid string, false otherwise.
 */
const isValidPpid = (val: string): boolean => typeof val === 'string' && val.length >= 32 && val.length <= 150;

/**
 * Generates a new PPID, sets it in the cookie, and returns the generated PPID.
 *
 * @returns {string} - The newly generated PPID.
 */
const getNewPpid = (): string => {
  const id = Date.now() + ('' + Math.floor(100000000 + Math.random() * 10000000)).substring(1);
  setCookie(PPID_COOKIE, id, 400, 'high');
  return id;
};

const ppid = {
  get: getPPID,
  isValidPPID: isValidPpid,
};

export default ppid;
