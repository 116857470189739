import AdsManager from './AdsManager';
import { configureForDevelopment } from './placement/local';
import { enableGrowthbook, getGrowthbookGroupVideo } from './abtest/growthbook';
import adSettings from './config/adsettings';
import { getAdScheduleId } from './config/adschedule';
import ppid from './config/ppid';
import adConfig from './config/adslots';
import initConsent, { checkForUserConsent } from './consent/consent';
import initGpt from './googletag/googletag';
import log from './helpers/log';
import { collector } from './adstats/collector';
import waitFor from './helpers/waitfor';
import { initAdResources } from './resource/resource';
import { isOcelotSite } from './config/domain';
import { isVideoPage } from './config/domain';
import { stringIfAny } from './helpers/utils';

let processedIds: string[] = [];
const processed = { divIds: [] as string[] };
const hasValidAdsUniqueId = (id: string) => typeof id !== 'undefined' && !processedIds.includes(id);

const isPlacementWrappersLoaded = (uniqueId: string) => 
  window.allerAds ? window.allerAds?.loadedAdPlacement?.[uniqueId] : window?.loadedAdPlacement?.[uniqueId];
  

/**
 * Initializes the loading of ads on a page.
 * @param uniqueId The unique ID appended to placements div-ids.
 * @param options Custom settings, e.g., { dfpSection = "..." }.
 * @returns A Promise that resolves once the initialization is complete.
 */
const initializeAds = async (adsUniqueId: string | number = window.allerAds ? window.allerAds.adsUniqueId : window.adsUniqueId, options: InitOptions = {}): Promise<void> => {
  try {
    let uniqueId = stringIfAny(adsUniqueId);
    let { videoOnly, dfpSection, isSecondArticle = false } = options;

    if (!videoOnly && !hasValidAdsUniqueId(uniqueId)) {
      processedIds.includes(uniqueId) && console.warn(`Ads:: already processed ${uniqueId}`);

      log('Ads:: Valid uniqueId not set yet, waiting for updated window.allerAds.adsUniqueId ...');
      await waitFor(() => hasValidAdsUniqueId(stringIfAny(window.allerAds ? window.allerAds.adsUniqueId : window.adsUniqueId)), {
        timeout: 5000,
        errorMessage: `Timeout: Valid adsUniqueId is missing - ${uniqueId}`,
      });
      uniqueId = stringIfAny(window.allerAds ? window.allerAds.adsUniqueId : window.adsUniqueId);
    }
    log(`Ads:: ${window.allerAds?.adsUniqueId ? 'window.allerAds.adsUniqueId' :'' } , ${window.adsUniqueId ? 'window.adsUniqueId' :'' } = `);

    processedIds.push(uniqueId);

    adSettings.refresh();

    log('Get config for ', uniqueId, adSettings.hostName, adSettings.device, { ...adSettings });

    const pageNo = [...processedIds].length;

    let config: any = await adConfig.getModifiedConfig(adSettings.hostName);

///////////////////
//////////////////////////////////////////
/////////////

    config = adConfig.addExtraPlacements(config, 'intext', processed);
    config = adConfig.addExtraPlacements(config, 'responsive', processed);

    if (!videoOnly && isOcelotSite()) {
      if (!isPlacementWrappersLoaded(uniqueId)) {
        log(`Ads:: Wait for Placement Wrappers to be loaded for ${uniqueId} ...`);
        await waitFor(() => isPlacementWrappersLoaded(uniqueId), {
          timeout: 5000,
          errorMessage: `Timeout: Placementwrappers are missing for ${uniqueId}`,
        });
      }
      log('Ads:: Placement Wrappers Loaded for ', uniqueId);
    }

    let filteredAds: any = adSettings.filterAds ? adConfig.filter(config, adSettings.isNativeAd) : config;

    log('filteredAds incl only no-processed', filteredAds);

    await initConsent();

    initGpt();

    collector.start(config?.statscollector);

    await enableGrowthbook();
    const hasConsent = await checkForUserConsent();
    if (!hasConsent) {
      if (window.allerAds) {
        window.allerAds.adsUseDemandManager = 'false';
      } else {
        window.adsUseDemandManager = 'false';
      }
      dfpSection = 'optout';
    }
    AdsManager.init(filteredAds, ppid.get(), uniqueId, dfpSection, pageNo, hasConsent, videoOnly, isSecondArticle );
  } catch (error) {
    console.error('Error initializing ads:', error);
  }
};

// get attribute ads-conf from current script
const adsPlatformConfJson = document.getElementById('ads-manager')?.getAttribute('data-ads-conf');

initAdResources(adsPlatformConfJson)
  .then(() => {
    log('AdResources loaded');

    if(window.allerAds){
      window.allerAds.init = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {
        log('DFPInit - window.allerAds.init called initializeAds ...');
        initializeAds(uniqueId, options);
      };  
      log('window.allerAds.init available');
    }
    window.DFPInit = async (uniqueId?: string | number, options?: InitOptions): Promise<void> => {
      log('DFPInit - window.DFPInit called initializeAds ...');
      initializeAds(uniqueId, options);
    };
    log('window.DFPInit available');

    adSettings.init();
    adSettings.autoloadAds && initializeAds(window.allerAds ? window.allerAds.adsUniqueId : window.adsUniqueId , { videoOnly: isVideoPage() });

    window.ads = {
      getAdScheduleId,
      getVideoExperimentGroup: getGrowthbookGroupVideo,
      getVideoPpId: ppid.get,
    };
    if (window.allerAds) {
      window.allerAds.video = {
        getAdScheduleId,
        getVideoExperimentGroup: getGrowthbookGroupVideo,
        getVideoPpId: ppid.get,
      };  
      log('window.allerAds.video available');
    }

    log('window.ads available');
  })
  .catch((error) => {
    console.error('AdResources fail to load', error);
  });
