/** @typedef{string} Devices */
/** @enum {device} */
export const Devices = {
  DESKTOP: 'desktop',
  MOBILE: 'mobile',
};

/**
 * Breakpoint when we build the size mapping for ad slots. When we
 * go from mobile to desktop platform
 */
let bp = 480;
if (window.allerAds) {
  bp = typeof window.allerAds.desktopBreakpoint === 'number' ? window.allerAds.desktopBreakpoint : bp;

} else {
  bp = typeof window.desktopBreakpoint === 'number' ? window.desktopBreakpoint : bp;
}
export const DESKTOP_BREAKPOINT: number[] = [bp, bp];

// Test userAgent if we are mobile
export const isMobile =
  /Android|webOS|iPhone|iPad|iPod|BlackBerry|BB|PlayBook|IEMobile|Windows Phone|Kindle|Silk|Opera Mini|Mobile/i // tslint:disable-line
    .test(navigator.userAgent);

// Test userAgent if we are Tablet
export const isTablet =
  /(ipad|tablet|(android(?!.*mobile))|(windows(?!.*phone)(.*touch))|kindle|playbook|silk|(puffin(?!.*(IP|AP|WP))))/.test(
    navigator.userAgent.toLowerCase(),
  );

// Test userAgent is in facebook browser
export const isFacebookInAppBrowser = () =>
  /FB_IAB/i.test(navigator.userAgent) ||
  /FBAN/i.test(navigator.userAgent) ||
  /FBAV/i.test(navigator.userAgent) ||
  document.referrer.toLowerCase().includes('facebook');

// Test userAgent is in Instagram browser
export const isInstagramInAppBrowser = () =>
  /Instagram/i.test(navigator.userAgent) || document.referrer.toLowerCase().includes('instagram');

// Test userAgent is in TikTok browser
export const isTikTokInAppBrowser = () =>
  /TikTok/i.test(navigator.userAgent) || document.referrer.toLowerCase().includes('tiktok');

// Test userAgent is in Pinterest browser
export const isPinterestInAppBrowser = () =>
  /Pinterest/i.test(navigator.userAgent) || document.referrer.toLowerCase().includes('pinterest');

export const isChromeBrowser = () =>
  /chrome|crios/i.test(navigator.userAgent) && !/opr|opera|chromium|edg|ucbrowser|googlebot/i.test(navigator.userAgent);

// track app
export const isApp = () => window.location.search.includes('allerapp=1');

// track category page
export const isCategoryPage = () => {
  const pathname = window.location.pathname;
  const categories = pathname.split('/').filter((category) => category !== ''); // Exclude empty categories if any
  if (categories.length === 1) return true;
};

const getParameterByName = (name: string) => {
  const urlParams = new URLSearchParams(location.search);
  return urlParams.has(name);
};

// track reffered pages
export const isRefferedPage = () => {
  const referrerArray = [
    'bing',
    'google',
    'yahoo',
    'baidu',
    'duckduckgo',
    'yandex',
    'earch.aol',
    'ecosia',
    'ask',
    'startpage',
    'qwant',
    'dailymotion',
    'yippy',
    'mojeek',
    'swisscows',
    'searchencrypt',
    'gigablast',
    'onesearch',
    'searx',
    'info.com',
    'webcrawler',
  ];
  return (
    referrerArray.some((element) => document.referrer.toLowerCase().includes(element)) ||
    getParameterByName('utm_source')
  );
};
export const isComingFromPushPushGo = () => {
  const referrer = document.referrer.toLowerCase();
  return referrer.includes('pushpushgo');
};
export const isGoogleDiscover = () => {
  const referrer = document.referrer.toLowerCase();
  return referrer.includes('com.google.android.googlequicksearchbox');
};

// Will be used to determine if we load mobile or desktop ad units
const windowWidth = window.innerWidth;
export const isDesktop = () => windowWidth >= DESKTOP_BREAKPOINT[0];
export const getDevice = () => (windowWidth >= DESKTOP_BREAKPOINT[0] ? Devices.DESKTOP : Devices.MOBILE);
