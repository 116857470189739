import log from '../helpers/log';

type LoadScriptOptions = {
    async?: boolean;
    defer?: boolean;
    footer?: boolean;
    referrerpolicy?: string;
    timeout?: number;
    errorPropagation?: boolean;
    timeoutError?: string;
    [key: string]: any;
}

const loadScript = async (
  src: string,
  {
    async = false,
    defer = true,
    footer = false,
    referrerpolicy = undefined,
    timeout = 60000,
    errorPropagation = false,
    timeoutError = 'timeoutError',
    ...args
} : LoadScriptOptions = {},
) => {
  let intervalId: NodeJS.Timeout;
  log('timeout', timeout, src);
  return Promise.race([
    new Promise((_, reject) => (intervalId = setTimeout(reject, timeout, timeoutError))),
    new Promise((resolve, reject) => {
      const el = document.createElement('script');
      el.src = src;
      el.defer = defer;
      el.async = async;
      if (referrerpolicy) {
        el.referrerPolicy = referrerpolicy;
      }
      Object.keys(args).map((key) => {
        const dataValue = args[key];
        if (typeof dataValue === 'undefined') {
          throw `Invalid value for ${key}`;
        }
        return (el.dataset[key] = dataValue);
      });
      el.onload = () => resolve({ status: 'success', src });
      el.onerror = (e) => reject(`Load for ${src} failed: ${e}`);
      if (footer) {
        typeof document.getElementsByTagName('footer') !== 'undefined'
          ? document.getElementsByTagName('footer')[0].appendChild(el)
          : console.error(`footer is not available for applying load script`);
      } else {
        document.head.appendChild(el);
      }
    }),
  ])
    .catch((e) => {
      if (errorPropagation) throw e;
      console.warn('Loadscript catch error', e);
    })
    .finally(() => {
      clearInterval(intervalId);
    });
};

export default loadScript;
