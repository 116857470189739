import log from "../helpers/log";
import { getDevice } from "./device";
import { getSiteName } from "./domain";

export type LoadedByAdsLib = {
    gpt?: boolean,
    demandManager?: boolean,
    adnami?: boolean,
    config?: boolean,
};

export type AdsConfigJson = {
    siteName?: string;
    pageMeta?: {
        isNativeAd?: boolean;
        hideAds?: '0' | '1';
        device?: 'mobile' | 'desktop';
    };
    adsData?: {
        adsConfig?: {
            ads?: {};
            dfpId?: string;
        }; // response from https://se-ads-microservice.ads.allermedia.io/api/v1/config/get[site]
        adsUniqueId?: string;
    };
    preConfig?: {
        growthbooks?: GrowthbookKeyValue[];
        enableDemandManager?: 'true' | 'false';
    }; // response from https://se-ads-microservice.ads.allermedia.io/api/v1/config/preFetch?site_id=[site]
    topAdTarget?: string;   // div id for top ad placement
    loadedByAdsLib?: LoadedByAdsLib;
  
};
export const parseAdsConfig = (adsConfigJson: string | undefined) : AdsConfigJson => adsConfigJson ? JSON.parse(adsConfigJson) : {};

export const loadConfig = (adsConfigJson: AdsConfigJson) => {
    const {
        siteName,
        pageMeta = {},
        adsData = {},
        preConfig = {},
        topAdTarget = '',
        loadedByAdsLib = {
            gpt: false,
            demandManager: true,
            adnami: false,
            config: true,
        },
    } = adsConfigJson;

    if (loadedByAdsLib?.config) {
        const displayAds = !(pageMeta?.isNativeAd || pageMeta?.hideAds === '1') ? 'true' : 'false';
        const isNativeAd = pageMeta?.isNativeAd || pageMeta?.hideAds === '1';
        const adsDevice = pageMeta?.device || getDevice();
        
        const adsUniqueId = adsData.adsUniqueId || '';
        const { adsConfig = {} } = adsData;
        const adsConfigStr = (adsConfig?.ads && JSON.stringify(adsConfig)) || '';
        const dfpId = adsConfig?.dfpId;
        
        const growthbooks = preConfig.growthbooks || [];
        const defaultEnableDemandManager = true;
        const defaultAutoLoadAds = true;
        const defaultFilterAds = true;
        const adsUseDemandManager =
            typeof preConfig?.enableDemandManager === 'undefined'
            ? defaultEnableDemandManager
            : preConfig.enableDemandManager === 'true';
        
        
        if(window.allerAds) {
            window.allerAds = { ...window.allerAds,  ... {
                siteName: `${siteName ? siteName : getSiteName()}`,
                isMainSite: !!window.isMainSite,        
                adsUniqueId: window.allerAds && window.allerAds.adsUniqueId || window.adsUniqueId || `${adsUniqueId}`,         
                displayAds: `${displayAds}`,
                adsUseDemandManager: `${adsUseDemandManager}`,
                autoloadAds: `${defaultAutoLoadAds}`,
                device: `${adsDevice}`,
                filterAds: `${defaultFilterAds}`,
                growthbooks: growthbooks,
                isNativeAd: `${isNativeAd}`, 
                }
            }    
        } else {
            // Deprecated, will be removed when all platforms/users has window.allerAds
            window.siteName =  `${siteName ? siteName : getSiteName()}`;        
            window.adsUniqueId = window.adsUniqueId || `${adsUniqueId}`;
            
            window.displayAds = `${displayAds}`;
            window.adsUseDemandManager = `${adsUseDemandManager}`;
            window.autoloadAds = `${defaultAutoLoadAds}`;
            window.device = `${adsDevice}`;
            window.filterAds = `${defaultFilterAds}`;
            window.growthbooks = growthbooks;
            window.isNativeAd = `${isNativeAd}`;
        }


        
        // top-ad placement
        const isApp = window.location.search.includes('allerapp=1');
        let topAdElement = document.createElement('div');
        topAdElement.id = `${topAdTarget}`;
        topAdElement.setAttribute('ad-placement', 'top_ad');
        if (!isApp) {
            window.document.body.insertBefore(topAdElement, window.document.body.firstChild);
        }
        
        const ad_cfg_val = sessionStorage.getItem(`ad_cfg_${dfpId}`);
        if (!ad_cfg_val) sessionStorage.setItem(`ad_cfg_${dfpId}`, adsConfigStr);
        log('Platform adsConfig is loaded');            
    } else {
        log('Platform adsConfig not loaded by ads-lib');
    }
}

